import { Form, Formik } from 'formik';
import { useContext } from 'react';

import Alert from '../../../../common/base/Alert';
import Button from '../../../../common/base/Button';
import { StyledCard } from '../../../../common/base/Card';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { UserContext } from '../../../../contexts/UserContext';

const AccountAuthentication: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user, mutateUser } = useContext(UserContext);
  const { addToast } = useToasts();

  const handleSubmit = (values) => {
    return HookdeckAPI.session
      .updatePassword(values.new_password, user!.has_password ? values.old_password : null)
      .then(() => {
        mutateUser!({ ...user!, has_password: true });
        addToast('success', 'Password updated');
      })
      .catch((e) => {
        addToast('error', `Failed to update password: ${e.response?.message}`);
      });
  };

  if (user!.sso_idp_id) {
    return (
      <Alert inline info m={{ b: 14 }}>
        This account is managed by your organization SSO provider. To change your password or
        credentials contact your administrator.
      </Alert>
    );
  }

  return (
    <>
      <Div m={{ b: 14 }}>
        <Text bold size="l" as="h2" m={{ b: 2 }}>
          Password
        </Text>
        <Formik
          initialValues={{
            new_password: '',
            old_password: '',
          }}
          validate={(values: { new_password: string; old_password: string }) => {
            const errors: { new_password?: string; old_password?: string } = {};
            if (user!.has_password && (!values.old_password || values.old_password.length === 0)) {
              errors.old_password = 'Required';
            }
            if (!values.new_password || values.new_password.length === 0) {
              errors.new_password = 'Required';
            } else if (values.new_password.length < 8) {
              errors.new_password = 'Password must contain at least 8 characters';
            }
            return errors;
          }}
          onSubmit={(values, { resetForm }) =>
            handleSubmit(values).then(() => {
              resetForm({});
            })
          }>
          {(props) => (
            <Form>
              {user!.has_password && (
                <TextInput
                  label="Current password"
                  name="old_password"
                  type="password"
                  auto_complete="current-password"
                  required
                />
              )}
              <TextInput
                label={user!.has_password ? 'New password' : ''}
                name="new_password"
                type="password"
                auto_complete="new-password"
                required
              />
              <Button
                disabled={
                  !props.isValid ||
                  !props.touched ||
                  props.isSubmitting ||
                  !props.values.new_password ||
                  !props.values.new_password
                }
                m={{ t: 1 }}
                primary
                submit
                icon={props.isSubmitting ? 'loading' : 'save'}>
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </Div>
      <Div m={{ b: 14 }}>
        <Text bold size="l" as="h2" m={{ b: 4 }}>
          Linked accounts
        </Text>
        {user!.github_id && (
          <StyledCard flex={{ align: 'center' }} m={{ b: 4 }} p={4}>
            <Icon left src="/images/github-icon.svg" />
            Authenticated using Github SSO
          </StyledCard>
        )}
        {user!.google_id && (
          <StyledCard flex={{ align: 'center' }} m={{ b: 4 }} p={4}>
            <Icon left src="/images/google-icon.svg" />
            Authenticated using Google SSO
          </StyledCard>
        )}
        {!user!.google_id && !user!.github_id && (
          <StyledCard p={4}>
            <Text muted center>
              No linked account
            </Text>
          </StyledCard>
        )}
      </Div>
    </>
  );
};

export default AccountAuthentication;
