import { Form, Formik } from 'formik';
import { useContext } from 'react';

import { isFreePlan } from '../../../../../utils/subscription';
import Button from '../../../../common/base/Button';
import { StyledCard } from '../../../../common/base/Card';
import Container from '../../../../common/base/Container';
import Divider from '../../../../common/base/Divider';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import Tooltip from '../../../../common/base/Tooltip';
import { useDialog } from '../../../../common/Dialog';
import SwitchInput from '../../../../common/Form/Fields/SwitchInput';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { UserContext } from '../../../../contexts/UserContext';
import { DashboardContext } from '../../DashboardContext';
import AccountAuthentication from './AccountAuthentication';

const AccountProfile: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user, mutateUser } = useContext(UserContext);
  const { addToast } = useToasts();
  const { subscription, organizations, logout } = useContext(DashboardContext);
  const showDialog = useDialog();

  const user_owns_paid_orgs = !!organizations?.some(
    (org) =>
      org?.plan &&
      !isFreePlan(org?.plan) &&
      org?.role === 'owner' &&
      (!subscription?.cancel_at || new Date(subscription.cancel_at).getTime() > Date.now()),
  );
  const user_is_deletable = !user?.workos_profile_id && !user_owns_paid_orgs;

  const onDelete = () =>
    showDialog(
      () => {
        return HookdeckAPI.users
          .delete()
          .then(() => {
            logout();
          })
          .catch((e) => {
            const error_message =
              e?.response?.message || 'The account could not be deleted, please contact us.';
            addToast('error', error_message);
          });
      },
      undefined,
      {
        title: 'Delete account',
        submit_label: 'Delete account',
        cancel_label: 'Cancel',
        submit_icon: 'delete',
        danger: true,
        form_props: {
          initial_values: {
            name: '',
          },
          validate: ({ name }: { name: string }) => {
            if (!name || name.length < 0) return { name: 'Required' };
            if (name !== user?.email) {
              return { name: 'Account email does not match.' };
            }
            return {};
          },
          Fields: () => (
            <>
              <Text m={{ b: 4 }}>
                This action can't be undone. If you delete your account, any organizations that you
                are the only member of will also be deleted. You'll lose your entire billing
                history, as well as the projects within those organizations and all of their
                associated data. Are you sure?
                <br />
                <br />
                If you’d like to delete this account, type <strong>{user?.email}</strong> below to
                confirm.
              </Text>
              <TextInput m={0} name="name" placeholder={user?.email} required />
            </>
          ),
        },
      },
    );

  const handleSubmit = (promise) => {
    return promise
      .then((user) => {
        mutateUser && mutateUser(user);
        addToast('success', 'Profile updated');
      })
      .catch(() => {
        addToast('error', 'Failed to update profile');
      });
  };

  return (
    <>
      <Container medium left>
        <Div m={{ b: 14 }}>
          <Formik
            initialValues={{
              name: user!.name,
              email: user!.email,
            }}
            validate={(values: { name?: string; email?: string }) => {
              const errors: { name?: string; email?: string } = {};
              if (!values.name || values.name.length === 0) {
                errors.name = 'Required';
              }
              return errors;
            }}
            onSubmit={(values) =>
              handleSubmit(
                HookdeckAPI.session.updateMe({
                  name: values.name,
                }),
              )
            }>
            {(props) => (
              <Form>
                <Div m={{ b: 14 }}>
                  <Text bold size="l" as="h2" m={{ t: 0, b: 4 }}>
                    Your name
                  </Text>
                  <TextInput label="Name" name="name" required m={{ b: 5 }} />
                  <Button
                    disabled={
                      !props.isValid ||
                      !props.touched ||
                      props.isSubmitting ||
                      user!.name === props.values.name
                    }
                    m={{ t: 1 }}
                    primary
                    submit
                    icon={props.isSubmitting ? 'loading' : 'save'}>
                    Save
                  </Button>
                </Div>
                <Div m={{ b: 14 }}>
                  <Text bold size="l" as="h2" m={{ t: 0, b: 4 }}>
                    Your email
                  </Text>
                  <TextInput
                    disabled
                    name="email"
                    type="email"
                    help="Contact us to change your email"
                    m={{ b: 5 }}
                  />
                  <Button neutral submit icon="chat">
                    Contact us
                  </Button>
                </Div>
              </Form>
            )}
          </Formik>
        </Div>
        <AccountAuthentication />
      </Container>
      <Div m={{ b: 12 }} p={{ x: 8 }}>
        <Divider />
      </Div>
      <Container medium left>
        <Div m={{ b: 14 }}>
          <Formik
            initialValues={{
              enable_alerts: !user!.disable_alerts,
            }}
            onSubmit={(values) => {
              return handleSubmit(
                HookdeckAPI.session.updateMe({
                  disable_alerts: !values.enable_alerts,
                }),
              );
            }}>
            {(props) => (
              <Form>
                <Text bold size="l" as="h2" m={{ b: 4 }}>
                  Notification settings
                </Text>
                <StyledCard
                  p={4}
                  flex={{ justify: 'flex-start', align: 'center', gap: 3 }}
                  m={{ b: 4 }}>
                  <SwitchInput name="enable_alerts" onChange={() => props.submitForm()} />
                  <Div flex={{ align: 'center', gap: 1 }}>
                    <Text>Email notifications</Text>
                    <Tooltip tooltip="Receive platform updates via email.">
                      <Icon muted icon="info" />
                    </Tooltip>
                  </Div>
                </StyledCard>
              </Form>
            )}
          </Formik>
        </Div>
      </Container>
      <Div m={{ b: 12 }} p={{ x: 8 }}>
        <Divider />
      </Div>
      <Container medium left m={{ b: 20 }}>
        <Div m={{ b: 4 }}>
          <Text bold size="l" as="h2" m={0}>
            Delete account
          </Text>
          <Text muted>Permanently delete your account and all associated account data.</Text>
        </Div>
        <Tooltip
          disabled={user_is_deletable}
          tooltip="You're the owner of an organization with a paid plan. In order to delete your account, you'll first need to either downgrade the organization to a free plan, or transfer ownership of the organization to a member within it.">
          <Button disabled={!user_is_deletable} danger icon="delete" onClick={onDelete}>
            Delete
          </Button>
        </Tooltip>
      </Container>
    </>
  );
};

export default AccountProfile;
